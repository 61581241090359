import { useEffect, useRef, useState } from 'react';
import styles from './ChatBotAgent.module.css';
import GrainyBg from '../../assets/images/GrainyBg';
import websocketService, { MESSAGE_TYPES } from '../../services/websocketService';
import axios from 'axios';

const ChatBotAgent = () => {
    const agentRef = useRef(null);
    const chatHistoryRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isFlying, setIsFlying] = useState(false);
    const [ripples, setRipples] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [messages, setMessages] = useState([]);
    const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
    const inputRef = useRef(null);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    entry.target.style.opacity = '1';
                }
            },
            { threshold: 0.1 }
        );

        if (agentRef.current) {
            observer.observe(agentRef.current);
        }

        return () => {
            if (agentRef.current) {
                observer.unobserve(agentRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (chatHistoryRef.current) {
            chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
        }
    }, [messages]);

    // Create ripple effect after flying animation
    useEffect(() => {
        if (isFlying) {
            // Start ripples when aiAgent begins growing (at 60% of flyAndGrow animation)
            const timeout = setTimeout(() => {
                // Create 3 ripples with slight delays
                for (let i = 0; i < 2; i++) {
                    setTimeout(() => {
                        setRipples(prev => [...prev, { id: Date.now() + i }]);
                    }, i * 200); // 200ms delay between each ripple
                }
            }, 1080); // 60% of 1.8s = 1.08s

            return () => clearTimeout(timeout);
        } else {
            setRipples([]); // Clear ripples when closing
        }
    }, [isFlying]);

    // Remove ripple after animation completes
    const handleRippleEnd = (rippleId) => {
        setRipples(prev => prev.filter(r => r.id !== rippleId));
    };

    const handleClick = () => {
        if (!isOpen) {
            setIsOpen(true);
            // Start flying animation after overlay starts
            setTimeout(() => {
                setIsFlying(true);
            }, 150);
        } else {
            setIsFlying(false);
            setTimeout(() => {
                setIsOpen(false);
            }, 1000);
        }
    };

    // Handle keyboard visibility
    useEffect(() => {
        const handleFocus = () => setIsKeyboardVisible(true);
        const handleBlur = () => setIsKeyboardVisible(false);

        const input = inputRef.current;
        if (input) {
            input.addEventListener('focus', handleFocus);
            input.addEventListener('blur', handleBlur);
        }

        return () => {
            if (input) {
                input.removeEventListener('focus', handleFocus);
                input.removeEventListener('blur', handleBlur);
            }
        };
    }, []);

    useEffect(() => {
        // Connect to WebSocket
        websocketService.connect();

        // Set up message handlers
        websocketService.onMessage(MESSAGE_TYPES.MESSAGE, handleIncomingMessage);
        websocketService.onMessage(MESSAGE_TYPES.ONLINE_STATUS, handleOnlineStatus);
        websocketService.onMessage(MESSAGE_TYPES.CONNECT, handleConnect);
        websocketService.onMessage(MESSAGE_TYPES.ERROR, handleError);

        // Cleanup on unmount
        return () => {
            websocketService.disconnect();
        };
    }, []);

    const handleConnect = (message) => {
        console.log('Connected to server:', message);
        setIsConnected(true);
    };

    const handleIncomingMessage = (message) => {
        setMessages(prev => [...prev, {
            type: 'assistant',
            content: message.content,
            timestamp: message.timestamp
        }]);
        scrollToBottom();
    };

    const handleOnlineStatus = (message) => {
        setOnlineUsers(message.users);
    };

    const handleError = (message) => {
        console.error('WebSocket error:', message);
        setIsConnected(false);
    };

    const handleSendMessage = async () => {
        if (inputValue.trim()) {
            // Add user message to chat
            setMessages(prev => [...prev, {
                type: 'user',
                content: inputValue,
                timestamp: new Date()
            }]);

            try {
                // Send message to backend
                const response = await axios.post('https://neurasite-a4fc889c98fe.herokuapp.com/userMessage', {
                    clientId: localStorage.getItem('wsClientId'),
                    message: inputValue
                });

                const data = response.data;
                console.log(data);

                // Clear input and blur keyboard
                setInputValue('');
                inputRef.current?.blur();
                scrollToBottom();
            } catch (error) {
                console.error('Error sending message:', error);
                // Optionally show error to user
            }
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    const scrollToBottom = () => {
        if (chatHistoryRef.current) {
            chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
        }
    };

    return (
        <>
            <div className={`${styles.overlay} ${!isOpen ? styles.transparentOverlay : ''} ${isKeyboardVisible ? styles.keyboardVisible : ''}`}>
                {isOpen && <GrainyBg opacity={0.9} color={"#000000"} />}
                {isOpen && (
                    <div className={styles.chatContainer}>
                        <div className={styles.chatHistory} ref={chatHistoryRef}>
                            {messages.map((message, index) => (
                                <div key={index} className={message.type === 'user' ? styles.userMessage : styles.assistantMessage}>
                                    {message.content}
                                </div>
                            ))}
                        </div>
                        <div className={styles.inputContainer}>
                            <input
                                ref={inputRef}
                                type="text"
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                onKeyPress={handleKeyPress}
                                placeholder="Type your message..."
                                disabled={!isConnected}
                            />
                            <div className={styles.sendArrow} onClick={handleSendMessage} />
                        </div>
                    </div>
                )}
                <div 
                    ref={agentRef} 
                    className={`${styles.aiAgent} ${isFlying ? styles.flying : ''}`}
                    onClick={handleClick}
                    style={{
                        opacity: 0,
                        transition: 'opacity 0.6s ease-out, transform 0.6s ease-out'
                    }}
                >
                    <div className={styles.blob}></div>
                </div>
                <div className={styles.rippleContainer}>
                    {ripples.map(ripple => (
                        <div
                            key={ripple.id}
                            className={styles.ripple}
                            onAnimationEnd={() => handleRippleEnd(ripple.id)}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};

export default ChatBotAgent;
