import Navbar from "../components/navbar/Navbar"
import Footer from "../components/footer/Footer"
import ContactForm from "../components/contactForm/ContactForm"

const Contact = () => {
    return (
        <>
            <Navbar />
            <ContactForm />
            <Footer />
        </>
    )
}

export default Contact;