import { useEffect, useRef } from 'react';
import styles from './AiFeatures.module.css';
import AgentBlob from '../../assets/images/AgentBlob.png';
import AgentBlog from '../../assets/images/AgentBlog.png';
import AgentChat from '../../assets/images/AgentChat.png';
import AgentRAG from '../../assets/images/AgentRAG.png';
import GrainyBg from '../../assets/images/GrainyBg';

const gradients = {
    customerService: {
        backgroundImage: `
            radial-gradient(circle farthest-corner at top left, rgb(179, 0, 255) 0%, rgba(225, 243, 97,0) 50%),
        radial-gradient(circle farthest-side at top right, rgb(255, 162, 0) 0%, rgba(181, 176, 177,0) 50%),
        radial-gradient(circle farthest-corner at bottom right, rgb(230, 21, 167) 0%, rgba(204, 104, 119, 0) 33%),
        radial-gradient(circle farthest-corner at top right, rgb(106, 0, 255) 0%, rgba(155, 221, 240,0) 50%),
            radial-gradient(ellipse at bottom center, rgb(53, 5, 210) 0%, rgba(254, 43, 0, 0) 80%),
            linear-gradient(to bottom, rgba(13, 17, 23, 0) 0%, rgba(13, 17, 23, 0.8) 70%, rgb(13, 17, 23) 100%)
        `
    },
    marketing: {
        backgroundImage: `
            radial-gradient(circle farthest-corner at top left, rgb(232, 152, 40) 0%, rgba(246, 91, 225,0) 50%),
            radial-gradient(circle farthest-side at top right, rgb(144, 0, 255) 0%, rgba(181, 176, 177,0) 50%), 
            radial-gradient(circle farthest-corner at bottom right, rgb(230, 21, 167) 0%, rgba(204, 104, 119, 0) 33%),
            radial-gradient(circle farthest-corner at top right, rgb(106, 0, 255) 0%, rgba(155, 221, 240,0) 50%),
            radial-gradient(ellipse at bottom center, rgb(210, 5, 200) 0%, rgba(254, 43, 0, 0) 80%),
            linear-gradient(to bottom, rgba(13, 17, 23, 0) 0%, rgba(13, 17, 23, 0.8) 70%, rgb(13, 17, 23) 100%)
        `
    },
    custom: {
        backgroundImage: `
            radial-gradient(circle farthest-corner at top left, rgb(57, 57, 189) 0%, rgba(107, 79, 174,0) 50%),
            radial-gradient(circle farthest-side at top right, rgb(255, 0, 183) 0%, rgba(181, 176, 177,0) 50%),
            radial-gradient(circle farthest-corner at bottom right, rgb(230, 21, 108) 0%, rgba(204, 104, 119, 0) 33%),
            radial-gradient(circle farthest-corner at top right, rgb(106, 0, 255) 0%, rgba(155, 221, 240,0) 50%),
            radial-gradient(ellipse at bottom center, rgb(210, 125, 5) 0%, rgba(254, 43, 0, 0) 80%),
            linear-gradient(to bottom, rgba(13, 17, 23, 0) 0%, rgba(13, 17, 23, 0.8) 70%, rgb(13, 17, 23) 100%)
        `
    }   
}

const FeaturesFlag = ({title, agentType, bulletPoints, image, gradient, flagRef}) => {
    return (
        <div ref={flagRef} className={styles.featuresFlag} style={{backgroundImage: gradients[gradient].backgroundImage}}>
            <GrainyBg opacity={0.9} color={"#000000"} />
            <div 
                className={styles.imageContainer} 
            >
                <div className={styles.aiAgent}>

                </div>
            </div>
            <div className={styles.flagContentContainer}>
                <div className={styles.agentType}>
                    <p>{agentType}</p>
                </div>
                <h3>{title}</h3>
                <p className={styles.workflow}>Workflow</p>
                {bulletPoints && (
                    <div className={styles.bulletPointsContainer}>
                        {bulletPoints.map((point, index) => (
                            <p key={index}>{point}</p>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

const AiFeatures = () => {
    const headerRef = useRef(null);
    const flag1Ref = useRef(null);
    const flag2Ref = useRef(null);
    const flag3Ref = useRef(null);

    useEffect(() => {
        setupScrollAnimations();
    }, []);

    const setupScrollAnimations = () => {
        // Add the slideFromBottom class to the elements initially
        if (headerRef.current) {
            headerRef.current.classList.add(styles.slideFromBottom);
        }
        if (flag1Ref.current) {
            flag1Ref.current.classList.add(styles.slideFromBottom);
        }
        if (flag2Ref.current) {
            flag2Ref.current.classList.add(styles.slideFromBottom);
        }
        if (flag3Ref.current) {
            flag3Ref.current.classList.add(styles.slideFromBottom);
        }

        // Create an Intersection Observer
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                // When element is in view
                if (entry.isIntersecting) {
                    // Add the visible class to trigger the animation
                    // Small timeout to ensure the animation starts after the page has settled
                    setTimeout(() => {
                        entry.target.classList.add(styles.slideVisible);
                    }, 100);
                    // Stop observing once it's visible
                    observer.unobserve(entry.target);
                }
            });
        }, {
            root: null, // Use the viewport
            threshold: 0.15, // Trigger when at least 15% of the element is visible
            rootMargin: '0px 0px -100px 0px' // Trigger a bit before the element enters the viewport
        });

        // Start observing the elements
        if (headerRef.current) {
            observer.observe(headerRef.current);
        }
        if (flag1Ref.current) {
            observer.observe(flag1Ref.current);
        }
        if (flag2Ref.current) {
            observer.observe(flag2Ref.current);
        }
        if (flag3Ref.current) {
            observer.observe(flag3Ref.current);
        }

        // Cleanup function
        return () => {
            if (headerRef.current) {
                observer.unobserve(headerRef.current);
            }
            if (flag1Ref.current) {
                observer.unobserve(flag1Ref.current);
            }
            if (flag2Ref.current) {
                observer.unobserve(flag2Ref.current);
            }
            if (flag3Ref.current) {
                observer.unobserve(flag3Ref.current);
            }
        };
    };

    return (
        <div className={styles.mainContainer}>
            <div ref={headerRef} className={styles.headerContainer}>
                <h2>AI-lösningar för ditt företag</h2>
                <p>Våra AI-lösningar hjälper dig att optimera dina processer, skapa engagerande innehåll och förbättra kundupplevelsen</p>
            </div>
            <div className={styles.contentContainer}>
                <FeaturesFlag 
                    title="AI-Chatbottar"
                    agentType="Kundservice"
                    flagRef={flag1Ref}
                    bulletPoints={[
                        "Automatiskt svar",
                        "Förbättra kundupplevelsen",
                        "Spara tid och resurser"
                    ]}
                    gradient="customerService"
                />
                <FeaturesFlag 
                    title="AI-Blog" 
                    agentType="Marknadsföring"
                    flagRef={flag2Ref}
                    bulletPoints={[
                        "Skapa engagerande innehåll",
                        "Förbättra SEO",
                        "Spara tid och resurser"
                    ]}
                    gradient="marketing"
                />
                <FeaturesFlag 
                    title="Skräddarsydda" 
                    agentType="AI-lösningar"
                    flagRef={flag3Ref}
                    bulletPoints={[
                        "Skapa engagerande innehåll",
                        "Förbättra SEO",
                        "Spara tid och resurser"
                    ]}
                    gradient="custom"
                />
            </div>
        </div>
    )
}

export default AiFeatures;
