import React, { useEffect, useRef } from 'react';
import styles from './ContactForm.module.css';
import GrainyBg from '../../assets/images/GrainyBg';

const ContactForm = () => {
    const formImageRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add(styles.slideVisible);
                    }
                });
            },
            {
                threshold: 0.1,
                rootMargin: '0px 0px -50px 0px'
            }
        );

        if (formImageRef.current) {
            observer.observe(formImageRef.current);
        }

        return () => {
            if (formImageRef.current) {
                observer.unobserve(formImageRef.current);
            }
        };
    }, []);

    return (
        <main className={styles.mainContainer}>
            <section className={styles.formContainer}>
                <div className={styles.formContent}>
                    <h2>Kontakta oss</h2>
                    <p>Du kan kontakta oss via formuläret, via våra sociala media eller via vårt email och telefonnummer.</p>
                    <div className={styles.contactInfo}>
                        <div className={styles.contactItem}>
                            <p>Email: <a href="mailto:alexander.neurasite@gmail.com">alexander.neurasite@gmail.com</a></p>
                        </div>
                        <div className={styles.contactItem}>
                            <p>Telefon: <a href="tel:+46732272453">+46 732 272 453</a></p>
                        </div>
                    </div>
                </div>
                <div ref={formImageRef} className={`${styles.formImageContainer} ${styles.slideFromBottom}`}>
                    <GrainyBg opacity={0.9} color={"#000000"}/>
                    <form className={styles.form}>
                        <div className={styles.inputGroup}>
                            <label>Name</label>
                            <div className={styles.nameContainer}>
                                <input type="text" placeholder="First" />
                                <input type="text" placeholder="Last" />
                            </div>
                        </div>
                        <div className={styles.inputGroup}>
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" placeholder="example@email.com" />
                        </div>
                        <div className={styles.inputGroup}>
                            <label htmlFor="phone">Phone (optional)</label>
                            <input type="tel" id="phone" placeholder="xxx-xxx-xxxx" />
                        </div>
                        <div className={styles.inputGroup}>
                            <label htmlFor="message">Message</label>
                            <textarea id="message" placeholder="Type your message..." />
                        </div>
                        <button type="submit" className={styles.submitButton}>SUBMIT</button>
                    </form>
                </div>
            </section>
        </main>
    )
}   

export default ContactForm;
