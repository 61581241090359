import styles from './Footer.module.css';
import NeurasiteIconText from '../../assets/icons/NeurasiteIconText';
import FacebookIcon from '../../assets/icons/FacebookIcon';
import InstagramIcon from '../../assets/icons/InstagramIcon';
import LinkedinIcon from '../../assets/icons/LinkedinIcon';
import GrainyBg from '../../assets/images/GrainyBg';

const Footer = () => {
    return (
        <footer className={styles.mainContainer}>
            <div className={styles.socialmediaContainer} aria-label="Sociala medier">
                <GrainyBg opacity={0.9} color={"#000000"}/>
                <h2>Anslut dig till oss på sociala medier</h2>
                <div className={styles.socialIcons}>
                    <a href="https://instagram.com/neurasite" aria-label="Följ oss på Instagram">
                        <InstagramIcon width={30} height={30} color={"rgba(0, 0, 0, 1)"} />
                    </a>
                    <a href="https://facebook.com/neurasite" aria-label="Följ oss på Facebook">
                        <FacebookIcon width={30} height={30} color={"rgba(0, 0, 0, 1)"} />
                    </a>
                    <a href="https://linkedin.com/company/neurasite" aria-label="Följ oss på LinkedIn">
                        <LinkedinIcon width={30} height={30} color={"rgba(0, 0, 0, 1)"} />
                    </a>
                </div>
            </div>
            
            <nav className={styles.navContainer} aria-label="Sidfotsmeny">
                <div className={styles.logoContainer}>
                    <NeurasiteIconText 
                        width={150} 
                        height={150} 
                        style={{position: "absolute", transform: "translate(-10%, -35%)"}}
                        aria-label="Neurasite logotyp"
                    />
                </div>
                
                <div className={styles.navSection}>
                    <h3>Tjänster</h3>
                    <a href="/hemsidor">Hemsidor</a>
                    <a href="/ai-losningar">AI-lösningar</a>
                </div>
                
                <div className={styles.navSection}>
                    <h3>Information</h3>
                    <a href="/anvandarvillkor">Användarvillkor</a>
                    <a href="/integritetspolicy">Integritetspolicy</a>
                </div>
                
                <div className={styles.navSection}>
                    <h3>Kontakta oss</h3>
                    <a href="tel:+46732272453" aria-label="Ring oss på 073-227 24 53">073-227 24 53</a>
                    <a href="mailto:alexander.neurasite@gmail.com">alexander.neurasite@gmail.com</a>
                </div>
            </nav>
        </footer>
    );
};

export default Footer;