import Footer from '../components/footer/Footer';
import Navbar from '../components/navbar/Navbar';
import AboutPage from '../components/aboutPage/AboutPage';
const About = () => {
    return (
        <div>
            <Navbar />
            <AboutPage />
            <Footer />
        </div>
    )
}

export default About;
