const GrainyBg = ({ opacity = 0.15, color = "#000000" }) => {
    return (
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            zIndex: 0,
            opacity: opacity,
            mixBlendMode: 'overlay'
        }}>
            <svg style={{
                width: '100%',
                height: '100%'
            }}
            xmlns="http://www.w3.org/2000/svg">
                <filter id="noise">
                    <feTurbulence 
                        type="fractalNoise" 
                        baseFrequency="0.65" 
                        numOctaves="3" 
                        stitchTiles="stitch" />
                    <feColorMatrix type="matrix"
                        values="1 0 0 0 0
                                0 1 0 0 0
                                0 0 1 0 0
                                0 0 0 1 0" />
                </filter>
                <rect width="100%" height="100%" filter="url(#noise)" fill={color} />
            </svg>
        </div>
    );
};

export default GrainyBg;
