import { useEffect, useState, useRef } from 'react';
import styles from './Payment.module.css';
import CheckmarkIcon from '../../assets/icons/CheckmarkIcon';
import XmarkIcon from '../../assets/icons/XmarkIcon';
import PlusIcon from '../../assets/icons/PlusIcon';
import GrainyBg from '../../assets/images/GrainyBg';

const PricingTier = ({ title, description, price, hostingPrice, premium, tierRef }) => {
    return (
        <div ref={tierRef} className={`${styles.paymentCard} ${premium ? styles.premium : ''}`} role="article">
            {premium && (
                <div className={styles.grainyBgContainer}>
                    <GrainyBg opacity={0.3} color={"#000000"}/>
                </div>
            )}
            <div className={styles.paymentTitle}>
                <h2>{title} Plan</h2>
                <p>{description}</p>
            </div>
            <div className={styles.paymentCost}>
                <h3><span className="sr-only">Pris: </span>{price}</h3>
                <p>per månad: {hostingPrice}</p>
            </div>
            <button type="button" aria-label={`Kontakta oss om ${title.toLowerCase()} plan`}>
                Kontakta oss
            </button>
        </div>
    );
}

const FeatureComparison = ({description, basic, standard, premium, isFirst}) => {
    const [isOpen, setIsOpen] = useState(false);

    const renderFeatureStatus = (plan, size) => (
        plan === 'yes' ? 
            <CheckmarkIcon 
                width={size} 
                height={size} 
                color="green" 
                aria-label="Ingår"
            /> : 
            <XmarkIcon 
                width={size} 
                height={size} 
                color="red" 
                aria-label="Ingår ej"
            />
    );

    return (
        <div className={`${styles.compare} ${isFirst ? styles.firstCompare : ''}`}>
                <>
                    <h4>{description}</h4>
                    {[basic, standard, premium].map((plan, index) => (
                        <div key={index} className={styles.checkmarkContainer}>
                            {renderFeatureStatus(plan, 30)}
                        </div>
                    ))}
                </>
        </div>
    );
};

const Payment = () => {
    const titleRef = useRef(null);
    const basicTierRef = useRef(null);
    const standardTierRef = useRef(null);
    const premiumTierRef = useRef(null);
    const compareRef = useRef(null);

    const setupScrollAnimations = () => {
        // Add the slideFromBottom class to the elements initially
        if (titleRef.current) {
            titleRef.current.classList.add(styles.slideFromBottom);
        }
        if (basicTierRef.current) {
            basicTierRef.current.classList.add(styles.slideFromBottom);
        }
        if (standardTierRef.current) {
            standardTierRef.current.classList.add(styles.slideFromBottom);
        }
        if (premiumTierRef.current) {
            premiumTierRef.current.classList.add(styles.slideFromBottom);
        }
        if (compareRef.current) {
            compareRef.current.classList.add(styles.slideFromBottom);
        }

        // Create an Intersection Observer
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                // When element is in view
                if (entry.isIntersecting) {
                    // Add the visible class to trigger the animation
                    // Small timeout to ensure the animation starts after the page has settled
                    setTimeout(() => {
                        entry.target.classList.add(styles.slideVisible);
                    }, 100);
                    // Stop observing once it's visible
                    observer.unobserve(entry.target);
                }
            });
        }, {
            root: null, // Use the viewport
            threshold: 0.15, // Trigger when at least 15% of the element is visible
            rootMargin: '0px 0px -100px 0px' // Trigger a bit before the element enters the viewport
        });

        // Start observing the elements
        if (titleRef.current) {
            observer.observe(titleRef.current);
        }
        if (basicTierRef.current) {
            observer.observe(basicTierRef.current);
        }
        if (standardTierRef.current) {
            observer.observe(standardTierRef.current);
        }
        if (premiumTierRef.current) {
            observer.observe(premiumTierRef.current);
        }
        if (compareRef.current) {
            observer.observe(compareRef.current);
        }

        // Cleanup function
        return () => {
            if (titleRef.current) {
                observer.unobserve(titleRef.current);
            }
            if (basicTierRef.current) {
                observer.unobserve(basicTierRef.current);
            }
            if (standardTierRef.current) {
                observer.unobserve(standardTierRef.current);
            }
            if (premiumTierRef.current) {
                observer.unobserve(premiumTierRef.current);
            }
            if (compareRef.current) {
                observer.unobserve(compareRef.current);
            }
        };
    };

    return (
        <main className={styles.mainContainer}>
            <header ref={titleRef} className={styles.titleContainer} aria-label="Introduktion">
                <h2>Kom igång med Neurasite</h2>
                <p>Välj en plan som passar dina behov och få hjälp att skapa en hemsida som verkligen speglar ditt varumärke.</p>
            </header>

            <section className={styles.firstContainer} aria-label="Prisplaner">
                <PricingTier 
                    title="Basic" 
                    description="Perfekt för små företag och nystartade verksamheter" 
                    price="25000kr"
                    hostingPrice="500kr"
                    tierRef={basicTierRef}
                />
                <PricingTier 
                    title="Standard" 
                    description="Idealisk för växande företag med större behov" 
                    price="30000kr"
                    hostingPrice="1000kr"
                    tierRef={standardTierRef}
                />
                <PricingTier 
                    title="Premium" 
                    description="Komplett lösning för etablerade företag" 
                    price="35000kr"
                    hostingPrice="1500kr" 
                    premium={true}
                    tierRef={premiumTierRef}
                />
            </section>

            <section ref={compareRef} className={styles.secondContainer} aria-label="Funktionsjämförelse">
                <div className={styles.compareContainer}>
                    <h2>Jämför funktioner</h2>
                    <h3>Basic</h3>
                    <h3>Standard</h3>
                    <h3 className={styles.comparePremium}>Premium</h3>
                </div>
                <FeatureComparison 
                    description="Responsiv webbdesign" 
                    basic="yes" 
                    standard="yes" 
                    premium="yes" 
                    isFirst={true}
                />
                <FeatureComparison 
                    description="SEO-optimering" 
                    basic="yes" 
                    standard="yes" 
                    premium="yes"
                />
                <FeatureComparison 
                    description="AI-integration" 
                    basic="no" 
                    standard="yes" 
                    premium="yes"
                />
                <FeatureComparison 
                    description="Valfri Agent" 
                    basic="no" 
                    standard="yes" 
                    premium="yes"
                />
                <FeatureComparison 
                    description="Två valfria Agenter" 
                    basic="no" 
                    standard="no" 
                    premium="yes"
                />
                                
            </section>
        </main>
    );
}

export default Payment;