import { useEffect, useRef } from 'react';
import styles from './References.module.css';
import QuoteIcon from '../../assets/icons/QuoteIcon';
import GrainyBg from '../../assets/images/GrainyBg';
import Melbin from '../../assets/images/Melbin.jpeg';
const companyReferences = [
    'Laboremus Uppsala',
    'TechPartner AB',
    'Digital Solutions',
    'Innovation Hub',
    'Web Experts',
    'AI Solutions'
];

const References = () => {
    const titleHeaderRef = useRef(null);
    const referenceContainerRef = useRef(null);
    const slideContainerRef = useRef(null);

    useEffect(() => {
        setupScrollAnimations();
        
        // Ensure the referenceContainer doesn't have animation classes directly
        if (referenceContainerRef.current && referenceContainerRef.current.parentElement) {
            referenceContainerRef.current.parentElement.classList.add(styles.slideFromBottom);
        }
    }, []);

    const setupScrollAnimations = () => {
        // Add the slideFromBottom class to the elements initially
        if (titleHeaderRef.current) {
            titleHeaderRef.current.classList.add(styles.slideFromBottom);
        }
        
        // For referenceContainer, we'll add the animation class to its parent wrapper
        const referenceWrapper = referenceContainerRef.current ? 
            referenceContainerRef.current.parentElement : null;
            
        if (referenceWrapper) {
            referenceWrapper.classList.add(styles.slideFromBottom);
        }
        
        if (slideContainerRef.current) {
            slideContainerRef.current.classList.add(styles.slideFromBottom);
        }

        // Create an Intersection Observer
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                // When element is in view
                if (entry.isIntersecting) {
                    // Add the visible class to trigger the animation
                    // Small timeout to ensure the animation starts after the page has settled
                    setTimeout(() => {
                        entry.target.classList.add(styles.slideVisible);
                    }, 100);
                    // Stop observing once it's visible
                    observer.unobserve(entry.target);
                }
            });
        }, {
            root: null, // Use the viewport
            threshold: 0.15, // Trigger when at least 15% of the element is visible
            rootMargin: '0px 0px -100px 0px' // Trigger a bit before the element enters the viewport
        });

        // Start observing the elements
        if (titleHeaderRef.current) {
            observer.observe(titleHeaderRef.current);
        }
        
        // Observe the wrapper instead of the container directly
        if (referenceWrapper) {
            observer.observe(referenceWrapper);
        }
        
        if (slideContainerRef.current) {
            observer.observe(slideContainerRef.current);
        }

        // Cleanup function
        return () => {
            if (titleHeaderRef.current) {
                observer.unobserve(titleHeaderRef.current);
            }
            if (referenceWrapper) {
                observer.unobserve(referenceWrapper);
            }
            if (slideContainerRef.current) {
                observer.unobserve(slideContainerRef.current);
            }
        };
    };

    return (
        <main className={styles.mainContainer}>
            <section className={styles.titleContainer} aria-label="Om våra kundrelationer">
                <div ref={titleHeaderRef} className={styles.titleHeader}>
                    <h2>Kunden i Fokus </h2>
                    <p>Vi lyssnar på dina visioner och skräddarsyr varje lösning för att hjälpa ditt företag att nå sina mål</p>
                </div>
            </section>

            {/* Wrapper div to handle the animation */}
            <div className={styles.referenceWrapper}>
                <section ref={referenceContainerRef} className={styles.referenceContainer} aria-label="Kundrecension">
                    <div className={styles.grainyBgContainer}>
                        <GrainyBg opacity={0.3} color={"#000000"}/>
                    </div>
                    <div className={styles.referenceText}>
                        <div className={styles.textContainer}>
                            <div className={styles.quote} aria-hidden="true">
                                <QuoteIcon 
                                    width="clamp(30px, 5vw, 50px)" 
                                    height="clamp(30px, 5vw, 50px)" 
                                    color="rgb(255, 255, 255)"
                                    alt="Citattecken"
                                />
                            </div>
                            <blockquote className={styles.text}>
                                <p>
                                    Neurasite har hjälpt oss att skapa en hemsida som verkligen speglar vårt varumärke. 
                                    Vi är mycket nöjda med resultatet och ser fram emot att fortsätta samarbetet.
                                </p>
                            </blockquote>
                        </div>
                        <div className={styles.imageSection}>
                            <div className={styles.imageContainer} role="img" aria-label="Profilbild av Axel Melbin">
                                <img src={Melbin} alt="Axel Melbin" />
                            </div>
                        </div>
                    </div>
                    <footer className={styles.about}>
                        <h2>Axel Melbin</h2>
                        <h3>Ordförande för Laboremus Uppsala</h3>
                    </footer>
                </section>
            </div>

            <section ref={slideContainerRef} className={styles.slideContainer} aria-label="Våra samarbetspartners">
                <div className={styles.companies}>
                    {companyReferences.slice(0, 3).map((company, index) => (
                        <h3 key={`company-${index}`}>{company}</h3>
                    ))}
                </div>
                <div className={styles.companies}>
                    {companyReferences.slice(3).map((company, index) => (
                        <h3 key={`company-${index + 3}`}>{company}</h3>
                    ))}
                </div>
            </section>
        </main>
    );
};

export default References;