import Navbar from '../components/navbar/Navbar'
import Frontpage from '../components/fronpage/Frontpage'
import SubContainer from '../components/subcontainer/Subcontainer'
import References from '../components/references/References'
import AiFeatures from '../components/aiFeatures/AiFeatures'
import Payment from '../components/payment/Payment'
import Footer from '../components/footer/Footer'
import Faq from '../components/faq/Faq'
import styles from './Home.module.css'
import ChatBotAgent from '../components/chatbotAgent/ChatBotAgent'

const Home = () => {
    return (
        <>
            <Navbar />
            <div className={styles.heroSection}>
                <Frontpage />
                <div className={styles.subContainerWrapper}>
                    <SubContainer />
                </div>
            </div>
            <AiFeatures />
            <References />
            <Payment />
            <Faq />
            <Footer />
            <ChatBotAgent />
        </>
    )
}

export default Home;