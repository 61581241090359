import styles from './Faq.module.css';
import PlusIcon from '../../assets/icons/PlusIcon';
import { useState } from 'react';

const QuestionLabel = ({question, answer}) => {
    const [isOpen, setIsOpen] = useState(false);
    
    const handleAnswer = () => {
        setIsOpen(!isOpen);
    };
    
    return (
        <div className={styles.questionLabel}>
            <div className={styles.questionHeader} onClick={handleAnswer}>
                <h3>{question}</h3>
                <PlusIcon 
                    width={30} 
                    height={30} 
                    color="rgba(148, 79, 174, 1)"
                    style={{ transform: `rotate(${isOpen ? '45' : '0'}deg)`, transition: 'transform 0.3s ease' }}
                />
            </div>
            <p style={{ 
                display: isOpen ? 'block' : 'none',
                transition: 'all 0.3s ease'
            }}>
                {answer}
            </p>
        </div>
    );
};

const Faq = () => {
    return (
        <main className={styles.mainContainer}>
            <header className={styles.titleContainer}>
                <h2>Vanliga frågor</h2>
            </header>
            <section className={styles.faqContainer} role="region" aria-label="FAQ Lista">
                <QuestionLabel question="Hur fungerar det?" answer="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec purus nec nunc." />
                <QuestionLabel question="Vad kostar det?" answer="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec purus nec nunc." />
                <QuestionLabel question="Hur lång tid tar det?" answer="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec purus nec nunc." />
                <QuestionLabel question="Vilka är vi?" answer="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec purus nec nunc." />
            </section>
        </main>
    );
}

export default Faq;