import { useEffect, useRef } from 'react';
import styles from './Frontpage.module.css';
import BlobFrontpage from '../../assets/icons/BlobFrontpage';
import Rocket from '../../assets/icons/Rocket';
import GrainyBg from '../../assets/images/GrainyBg';
import AgentBlog from '../../assets/images/AgentBlog.png';
import CheckmarkIcon from '../../assets/icons/CheckmarkIcon';


const ChatBubble = ({ message, title, style, button, grainy }) => {
    return (
        <div className={`${styles.chatBubble} ${grainy ? styles.grainy : ''}`} style={style}>
            {grainy && <GrainyBg opacity={0.9} color={"#000000"}/>}
            {title ? <h1>{message}</h1> : button ? <a href="/contact">{message} <span>&gt;</span></a> : <h3>{message}</h3>}
        </div>
    );
}

const Frontpage = () => {
    const filterRef = useRef(null);
    
    useEffect(() => {
        // Animation for the morphing effect
        const filter = filterRef.current;
        if (!filter) return;
        
        let seed = 0;
        const animate = () => {
            seed += 0.01;
            
            // Update the baseFrequency values to create the morphing effect
            const baseFrequencyX = 0.02 + Math.sin(seed) * 0.01;
            const baseFrequencyY = 0.02 + Math.cos(seed * 1.3) * 0.01;
            
            // Apply the new values
            const turbulence = filter.querySelector('feTurbulence');
            if (turbulence) {
                turbulence.setAttribute('baseFrequency', `${baseFrequencyX} ${baseFrequencyY}`);
            }
            
            // Update displacement scale for more dynamic effect
            const displacementMap = filter.querySelector('feDisplacementMap');
            if (displacementMap) {
                const scale = 15 + Math.sin(seed * 0.5) * 10;
                displacementMap.setAttribute('scale', scale.toString());
            }
            
            requestAnimationFrame(animate);
        };
        
        animate();
    }, []);

    return (
        <div className={styles.mainContainer}>
            <div className={styles.titleContainer}>
                <GrainyBg opacity={0.9} color={"#000000"}/>
                <h1>AI-drivna webbplatser</h1>
                <h2>Vi bygger smarta, snabba och användarvänliga webbplatser med AI-integration</h2>
                <div className={styles.ctaContainer}>
                    <button className={styles.bookButton} onClick={() => window.location.href = '/contact'}>KONTAKTA OSS
                        <GrainyBg opacity={0.9} color={"#000000"} />
                    </button>
                    <button className={styles.pricesButton} onClick={() => window.location.href = '/prices'}>SE PRISER</button>
                </div>
                {/* <div className={styles.imageContainer}>
                    <div className={styles.morphWrapper}>
                        <svg width="0" height="0" style={{ position: 'absolute' }}>
                            <filter id="morphFilter" ref={filterRef}>
                                <feTurbulence type="fractalNoise" baseFrequency="0.02 0.02" numOctaves="3" seed="1" result="turbulence" />
                                <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="20" xChannelSelector="R" yChannelSelector="G" result="displacementMap" />
                                <feGaussianBlur in="displacementMap" stdDeviation="0.5" result="blur" />
                                <feBlend in="blur" in2="SourceGraphic" mode="normal" />
                            </filter>
                        </svg>
                        <img src={AgentBlog} alt="AgentBlog" className={styles.morphImage} />
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default Frontpage;
