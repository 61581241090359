import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import NotFound from '../components/notfound/NotFound'

const Page404 = () => {
    return (
        <>
            <Navbar />
            <NotFound />
            <Footer />
        </>
    )
}

export default Page404;