// WebSocket message types
export const MESSAGE_TYPES = {
    CONNECT: 'connect',
    MESSAGE: 'message',
    DIRECT_MESSAGE: 'direct_message',
    ERROR: 'error',
    TYPING: 'typing',
    ONLINE_STATUS: 'online_status'
};

class WebSocketService {
    constructor() {
        this.socket = null;
        this.clientId = localStorage.getItem('wsClientId') || null;
        this.messageHandlers = new Map();
        this.reconnectAttempts = 0;
        this.maxReconnectAttempts = 5;
        this.reconnectDelay = 3000;
        this.isConnecting = false;
    }

    connect() {
        if (this.isConnecting) return;
        this.isConnecting = true;

        // Connect to Heroku backend using WSS
        const wsUrl = 'wss://neurasite-a4fc889c98fe.herokuapp.com';
        
        this.socket = new WebSocket(wsUrl);

        this.socket.onopen = () => {
            console.log('WebSocket connected to Heroku');
            this.reconnectAttempts = 0;
            this.isConnecting = false;
            
            // Send initial connection message with stored ID if available
            this.sendMessage(MESSAGE_TYPES.CONNECT, {
                id: this.clientId || null
            });
            console.log('Sent connection message with ID:', this.clientId);
        };

        this.socket.onmessage = (event) => {
            try {
                const message = JSON.parse(event.data);
                console.log('Received message:', message);
                
                // Store the clientId when we receive it
                if (message.type === MESSAGE_TYPES.CONNECT && message.id) {
                    // Only update if we don't already have an ID
                    if (!this.clientId) {
                        this.clientId = message.id;
                        localStorage.setItem('wsClientId', message.id);
                        console.log('Stored new client ID:', message.id);
                    }
                }
                
                this.handleMessage(message);
            } catch (error) {
                console.error('Error parsing message:', error);
            }
        };

        this.socket.onclose = () => {
            console.log('WebSocket disconnected from Heroku');
            this.isConnecting = false;
            this.handleReconnect();
        };

        this.socket.onerror = (error) => {
            console.error('WebSocket error:', error);
            this.isConnecting = false;
        };
    }

    handleReconnect() {
        if (this.reconnectAttempts < this.maxReconnectAttempts) {
            this.reconnectAttempts++;
            setTimeout(() => {
                console.log(`Attempting to reconnect (${this.reconnectAttempts}/${this.maxReconnectAttempts})`);
                this.connect();
            }, this.reconnectDelay);
        } else {
            console.error('Max reconnection attempts reached');
        }
    }

    handleMessage(message) {
        const handler = this.messageHandlers.get(message.type);
        if (handler) {
            handler(message);
        }
    }

    onMessage(type, handler) {
        this.messageHandlers.set(type, handler);
    }

    sendMessage(type, data) {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            const message = {
                type,
                ...data
            };
            this.socket.send(JSON.stringify(message));
        } else {
            console.error('WebSocket is not connected');
        }
    }

    sendChatMessage(content) {
        this.sendMessage(MESSAGE_TYPES.MESSAGE, { content });
    }

    sendDirectMessage(recipient, content) {
        this.sendMessage(MESSAGE_TYPES.DIRECT_MESSAGE, { recipient, content });
    }

    sendTypingStatus(recipient, isTyping) {
        this.sendMessage(MESSAGE_TYPES.TYPING, { recipient, isTyping });
    }

    disconnect() {
        if (this.socket) {
            this.socket.close();
        }
    }
}

// Create a singleton instance
const websocketService = new WebSocketService();
export default websocketService; 