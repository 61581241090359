import { useEffect, useRef } from 'react';
import styles from './Subcontainer.module.css';
import SearchIcon from '../../assets/icons/SearchIcon';
import AiNeurasiteBG from '../../assets/icons/AiNeurasiteBG';
import { motion } from 'motion/react';
import ChatBoxIcon from '../../assets/icons/ChatBoxIcon';
import DesktopWebsite from '../../assets/icons/DesktopWebsite';
import MobileWebsite from '../../assets/icons/MobileWebsite';
import SearchResults from '../../assets/icons/SearchResults';
import DatabaseServer from '../../assets/icons/DatabaseServer';
import AiWebsiteOptimization from '../../assets/icons/AiWebsiteOptimization';
import ChatBox from "../chatbox/ChatBox";
import GrainyBg from '../../assets/images/GrainyBg';
import ChatBotExample from '../../assets/images/ChatBotExample.png';
import SkewedChat from '../../assets/images/SkewedChat.png';
import SkewedMobile from '../../assets/images/SkewedMobile.png';
import SkewedSEO from '../../assets/images/SkewedSEO.png';
import SkewedHosting from '../../assets/images/SkewedHosting.png';

const chatMessages = [
    {robot: false, msg: "Hej, jag heter Neura och jag är här för att hjälpa dig!"},
    {robot: true, msg: "Hej, jag heter Neura och jag är här för att hjälpa dig!"},
    {robot: false, msg: "Hej, jag heter Neura och jag är här för att hjälpa dig!"},
    {robot: true, msg: "Hej, jag heter Neura, vad vill du ha hjälp med?", 
     points: ["• Hur kan jag hjälpa dig idag?", "• Behöver du information om våra tjänster eller support?", "• Letar du efter något speciellt? Jag guidar dig gärna!"]}
];

const keyboardKeys = ['Q','W','E','R','T','Y','U'];

const SubContainer = () => {
    const titleContentRef = useRef(null);
    const firstBoxOneRef = useRef(null);
    const firstBoxTwoRef = useRef(null);
    const secondBoxOneRef = useRef(null);
    const secondBoxTwoRef = useRef(null);
    const thirdBoxOneRef = useRef(null);
    const thirdBoxTwoRef = useRef(null);
    const fourthBoxOneRef = useRef(null);
    const fourthBoxTwoRef = useRef(null);

    useEffect(() => {
        setupDotConnectingLine();
        setupScrollAnimations();
    }, []);

    const setupDotConnectingLine = () => {
        if (!titleContentRef.current) return;
        const dots = titleContentRef.current.querySelectorAll(`.${styles.dot}`);
        if (dots.length < 2) return;

        const line = document.createElement('div');
        line.className = styles.line;
        titleContentRef.current.appendChild(line);
        
        const [firstDot, secondDot] = dots;
        const containerRect = titleContentRef.current.getBoundingClientRect();
        const {left, top, width} = firstDot.getBoundingClientRect();
        
        Object.assign(line.style, {
            position: 'absolute',
            left: `${left - containerRect.left + width/2 - 1}px`,
            top: `${top - containerRect.top + width/2}px`,
            width: '2px', 
            height: `${secondDot.getBoundingClientRect().top - top}px`
        });
    };

    const setupScrollAnimations = () => {
        // Add the slideFromBottom class to the elements initially
        if (firstBoxOneRef.current) {
            firstBoxOneRef.current.classList.add(styles.slideFromBottom);
        }
        if (firstBoxTwoRef.current) {
            firstBoxTwoRef.current.classList.add(styles.slideFromBottom);
        }

        if (secondBoxOneRef.current) {
            secondBoxOneRef.current.classList.add(styles.slideFromBottom);
        }

        if (secondBoxTwoRef.current) {
            secondBoxTwoRef.current.classList.add(styles.slideFromBottom);
        }

        if (thirdBoxOneRef.current) {
            thirdBoxOneRef.current.classList.add(styles.slideFromBottom);
        }

        if (thirdBoxTwoRef.current) {
            thirdBoxTwoRef.current.classList.add(styles.slideFromBottom);
        }

        if (fourthBoxOneRef.current) {
            fourthBoxOneRef.current.classList.add(styles.slideFromBottom);
        }

        if (fourthBoxTwoRef.current) {
            fourthBoxTwoRef.current.classList.add(styles.slideFromBottom);
        }

        // Create an Intersection Observer
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                // When element is in view
                if (entry.isIntersecting) {
                    // Add the visible class to trigger the animation
                    // Small timeout to ensure the animation starts after the page has settled
                    setTimeout(() => {
                        entry.target.classList.add(styles.slideVisible);
                    }, 100);
                    // Stop observing once it's visible
                    observer.unobserve(entry.target);
                }
            });
        }, {
            root: null, // Use the viewport
            threshold: 0.15, // Trigger when at least 15% of the element is visible
            rootMargin: '0px 0px -100px 0px' // Trigger a bit before the element enters the viewport
        });

        // Start observing the elements
        if (firstBoxOneRef.current) {
            observer.observe(firstBoxOneRef.current);
        }
        if (firstBoxTwoRef.current) {
            observer.observe(firstBoxTwoRef.current);
        }

        if (secondBoxOneRef.current) {
            observer.observe(secondBoxOneRef.current);
        }

        if (secondBoxTwoRef.current) {
            observer.observe(secondBoxTwoRef.current);
        }

        if (thirdBoxOneRef.current) {
            observer.observe(thirdBoxOneRef.current);
        }

        if (thirdBoxTwoRef.current) {
            observer.observe(thirdBoxTwoRef.current);
        }

        if (fourthBoxOneRef.current) {
            observer.observe(fourthBoxOneRef.current);
        }

        if (fourthBoxTwoRef.current) {
            observer.observe(fourthBoxTwoRef.current);
        }

        // Cleanup function
        return () => {
            if (firstBoxOneRef.current) {
                observer.unobserve(firstBoxOneRef.current);
            }
            if (firstBoxTwoRef.current) {
                observer.unobserve(firstBoxTwoRef.current);
            }
            if (secondBoxOneRef.current) {
                observer.unobserve(secondBoxOneRef.current);
            }
            if (secondBoxTwoRef.current) {
                observer.unobserve(secondBoxTwoRef.current);
            }
            if (thirdBoxOneRef.current) {
                observer.unobserve(thirdBoxOneRef.current);
            }
            if (thirdBoxTwoRef.current) {
                observer.unobserve(thirdBoxTwoRef.current);
            }
            if (fourthBoxOneRef.current) {
                observer.unobserve(fourthBoxOneRef.current);
            }
            if (fourthBoxTwoRef.current) {
                observer.unobserve(fourthBoxTwoRef.current);
            }
        };
    };

    return (
        <main className={styles.mainContainer}>
            <section className={styles.firstContainer} aria-label="Modern Design">
                <div ref={firstBoxOneRef} className={styles.firstBoxOne}>
                    <h2>AI-chatbot</h2>
                    <p>En stilren <span>AI-driven chatbot</span> som hjälper dig med allt från att svara på frågor till att hjälpa dig att navigera på hemsidan</p>
                </div>
                <div ref={firstBoxTwoRef} className={styles.firstBoxTwo}>
                    <GrainyBg opacity={0.9} color={"#000000"} />
                    <img src={SkewedChat} alt="AI Chatbot Interface" className={styles.chatBotExample} />
                </div>
            </section>

            <section className={styles.secondContainer} aria-label="Mobilanpassning">
                <div ref={secondBoxTwoRef} className={styles.secondBoxTwo}>
                    <h2>AI-blogg</h2>
                    <p>En AI-driven blogg som hjälper dig att skapa innehåll som är relevant och engagerande för dina besökare</p>
                </div>
                <div ref={secondBoxOneRef} className={styles.secondBoxOne}>
                    <GrainyBg opacity={0.9} color={"#000000"} />
                    <ul className={styles.mobileList}>
                        <h2>Mobilanpassad</h2>
                        <li>Responsiv design</li>
                        <li>Optimering för små skärmar</li>
                        <li>Snabbare laddning</li>
                        <li>Användarvänligt gränssnitt</li>
                    </ul>
                    <img src={SkewedMobile} alt="AI Chatbot Interface" className={styles.blogExample} />
                </div>
            </section>

            <section className={styles.thirdContainer} aria-label="SEO">
                <div ref={thirdBoxOneRef} className={styles.thirdBoxOne}>
                    <h2>SEO-optimering</h2>
                    <p>Våra SEO-optimerade hemsidor förbättrar din synlighet på nätet, ökar din organiska trafik och hjälper ditt företag att ranka högre på sökmotorer som Google.</p>
                    <a href="/seo">Läs mer om SEO-optimering &gt;</a>
                </div>
                <div ref={thirdBoxTwoRef} className={styles.thirdBoxTwo}>
                    <GrainyBg opacity={0.9} color={"#000000"} />
                    <img src={SkewedSEO} alt="AI Chatbot Interface" className={styles.seoExample} />
                </div>
            </section>

            <section className={styles.fourthContainer} aria-label="Hosting">
                <div ref={fourthBoxOneRef} className={styles.fourthBoxOne}>
                    <h2>Hosting</h2>
                    <p>Vår pålitliga och snabba hostingtjänst säkerställer att din hemsida är alltid tillgänglig, laddar snabbt och erbjuder optimal prestanda för både användare och sökmotorer.</p>
                    <a href="/hosting">Läs mer om hosting &gt;</a>
                </div>
                <div ref={fourthBoxTwoRef} className={styles.fourthBoxTwo}>
                    <GrainyBg opacity={0.9} color={"#000000"} />
                    <img src={SkewedHosting} alt="AI Chatbot Interface" className={styles.hostingExample} />
                </div>
            </section>
        </main>
    );
};

export default SubContainer;