import styles from './NotFound.module.css';
import Astronaut from '../../assets/icons/Astronaut';
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
        // Update document title programmatically
        document.title = '404 - Sidan hittades inte | Neurasite';
        
        const container = document.querySelector(`.${styles.mainContainer}`);
        const numberOfLines = 20;

        for (let i = 0; i < numberOfLines; i++) {
            const line = document.createElement('div');
            line.className = styles.line;
            
            const randomX = Math.random() * 100;
            const randomY = Math.random() * 100;
            line.style.left = `${randomX}%`;
            line.style.top = `${randomY}%`;
            
            const duration = 3 + Math.random() * 4;
            line.style.animation = `${styles.slideLine} ${duration}s linear infinite`;
            
            container.appendChild(line);
        }
    }, []);

    const handleHelp = () => navigate('/kontakt');
    const handleBack = () => window.history.back();
    
    return (
        <main className={styles.mainContainer} role="main">
            <div className={styles.titleContainer}>
                <h1>
                    <span aria-label="Felkod 404">404</span>
                    <br/>
                    Är du lost in space?
                </h1>
                <h2 className={styles.subheading}>
                    Förlåt, men den sidan du letar efter existerar inte
                </h2>
                <div className={styles.navigateContainer}>
                    <button 
                        className={styles.helpButton}
                        onClick={handleHelp}
                        aria-label="Få hjälp genom att kontakta oss"
                    >
                        HJÄLP
                    </button>
                    <button 
                        className={styles.backButton}
                        onClick={handleBack}
                        aria-label="Gå tillbaka till föregående sida"
                    >
                        GÅ TILLBAKA
                    </button>
                </div>
            </div>
            <div className={styles.imageContainer} aria-hidden="true">
                <Astronaut width={250} height={250} alt="" />
            </div>
        </main>
    );
};

export default NotFound;