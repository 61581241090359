import { useEffect, useRef } from 'react';
import styles from './AboutPage.module.css';
import GrainyBg from '../../assets/images/GrainyBg';
import GrowthImage from '../../assets/images/GrowthImage.png';
import LangChainIcon from '../../assets/icons/LangChainIcon';
import KerasIcon from '../../assets/icons/KerasIcon';
import MongoDBIcon from '../../assets/icons/MongoDBIcon';
import NodeJsIcon from '../../assets/icons/NodeJsIcon';
import ReactIcon from '../../assets/icons/ReactIcon';
import TensorFlowIcon from '../../assets/icons/TensorFlowIcon';

const AboutPage = () => {
    const headerRef = useRef(null);
    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);
    const section4Ref = useRef(null);
    const section5Ref = useRef(null);
    const section6Ref = useRef(null);
    const section7Ref = useRef(null);

    useEffect(() => {
        setupScrollAnimations();
    }, []);

    const setupScrollAnimations = () => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        entry.target.classList.add(styles.slideVisible);
                    }, 100);
                    observer.unobserve(entry.target);
                }
            });
        }, {
            threshold: 0.15,
            rootMargin: '0px 0px -50px 0px'
        });

        [headerRef, section1Ref, section2Ref, section3Ref, section4Ref, section5Ref, section6Ref, section7Ref].forEach(ref => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        return () => {
            [headerRef, section1Ref, section2Ref, section3Ref, section4Ref, section5Ref, section6Ref, section7Ref].forEach(ref => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    };

    return (
        <main className={styles.mainContainer}>
            <div ref={headerRef} className={`${styles.titleContainer} ${styles.slideFromBottom}`}>
                <h1>Om Oss</h1>
                <h2>Vi är ett team av passionerade experter inom AI och webbutveckling som strävar efter att skapa innovativa lösningar för moderna företag.</h2>
            </div>
            
            <div className={styles.firstContainer}>
                <div ref={section1Ref} className={`${styles.firstBoxTwo} ${styles.slideFromBottom}`}>
                    <GrainyBg opacity={0.9} color={"#000000"} />
                    <div className={styles.firstBoxTwoContent}>
                        <h2>Alexander Öhgren</h2>
                        <h3>Systemutvecklare</h3>
                        <p>En entusiastisk systemutvecklare med en passion för att skapa effektiva och användarvänliga lösningar. Med en bakgrund inom IT och ett stort intresse för att utveckla tekniska lösningar, har jag lärt mig att se möjligheter där andra ser begränsningar.
                        </p>
                        <button>KONTAKTA MIG</button>
                    </div>
                    <div className={styles.firstBoxTwoImage}>
                        <div className={styles.blob}>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.secondContainer}>
                <div ref={section2Ref} className={`${styles.secondBoxTwo} ${styles.slideFromBottom}`}>
                    <h2>Vår Vision</h2>
                    <p>Att revolutionera hur företag interagerar med AI genom att erbjuda skräddarsydda, användarvänliga lösningar som driver tillväxt och innovation.</p>
                </div>
                <div ref={section3Ref} className={`${styles.secondBoxOne} ${styles.slideFromBottom}`}>
                    <GrainyBg opacity={0.9} color={"#000000"} />
                    <img src={GrowthImage} className={styles.growthImage} alt="GrowthImage" />
                </div>
            </div>
            <div className={styles.thirdContainer}>
                <div ref={section4Ref} className={`${styles.thirdBoxOne} ${styles.slideFromBottom}`}>
                    <h2>Mina färdigheter</h2>
                    <p>Här är några av de tekniker och verktyg som jag har lärt mig och använt i mitt arbete.</p>
                </div>
                <div ref={section5Ref} className={`${styles.thirdBoxTwo} ${styles.slideFromBottom}`}>
                    <div className={styles.myExpertise}>
                        <LangChainIcon width={75} height={75} />
                    </div>
                    <div className={styles.myExpertise}>
                        <KerasIcon width={75} height={75} />
                    </div>
                    <div className={styles.myExpertise}>
                        <MongoDBIcon width={75} height={75} />
                    </div>
                    <div className={styles.myExpertise}>
                        <NodeJsIcon width={75} height={75} />
                    </div>
                    <div className={styles.myExpertise}>
                        <ReactIcon width={75} height={75} color={"rgb(0, 255, 187)"} />
                    </div>
                    <div className={styles.myExpertise}>
                        <TensorFlowIcon width={75} height={75} />
                    </div>
                </div>
            </div>
            <div className={styles.fourthContainer}>
                <div ref={section6Ref} className={`${styles.fourthBoxOne} ${styles.slideFromBottom}`}>
                    <h2>Vår Vision</h2>
                    <p>Att revolutionera hur företag interagerar med AI genom att erbjuda skräddarsydda, användarvänliga lösningar som driver tillväxt och innovation.</p>
                </div>
                <div ref={section7Ref} className={`${styles.fourthBoxTwo} ${styles.slideFromBottom}`}>
                    <GrainyBg opacity={0.9} color={"#000000"} />
                </div>
            </div>
        </main>
    );
};

export default AboutPage;
