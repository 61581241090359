import Navbar from "../components/navbar/Navbar";
import Payment from "../components/payment/Payment";
import Footer from "../components/footer/Footer";

const Prices = () => {
    return (
        <>
            <Navbar />
            <div style={{ paddingTop: '100px', backgroundColor: 'rgb(13, 17, 23)'}}>
                <Payment />
            </div>
            <Footer />
        </>
    );
};

export default Prices;