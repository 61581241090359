import styles from './Navbar.module.css';
import NeurasiteIconText from '../../assets/icons/NeurasiteIconText';
import { useState, useEffect } from 'react';

const Navbar = () => {
    const [navbarStyle, setNavbarStyle] = useState({
        backgroundColor: 'rgba(20, 20, 40, 0.05)',
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)',
        MozBackdropFilter: 'blur(10px)',
        OBackdropFilter: 'blur(10px)',
        msBackdropFilter: 'blur(10px)',
        boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.05)'
    });
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isServicesOpen, setIsServicesOpen] = useState(false);

    const handleHamburgerClick = () => {
        if (isMenuOpen) {
            handleHamburgerLeave();
        } else {
            setIsMobile(true);
            setIsMenuOpen(true);
            const mainContainer = document.querySelector(`.${styles.mainContainer}`);
            const servicesContainer = document.querySelector(`.${styles.servicesContainer}`);
            mainContainer.classList.add(styles.hovered);
            setTimeout(() => {
                servicesContainer.classList.add(styles.active);
            }, 500);
        }
    }

    const handleHamburgerLeave = () => {
        setIsMobile(false);
        setIsMenuOpen(false);
        const mainContainer = document.querySelector(`.${styles.mainContainer}`);
        const servicesContainer = document.querySelector(`.${styles.servicesContainer}`);
        servicesContainer.classList.remove(styles.active);
        mainContainer.classList.remove(styles.hovered);
    }

    const handleMouseEnter = () => {
        const mainContainer = document.querySelector(`.${styles.mainContainer}`);
        const servicesContainer = document.querySelector(`.${styles.servicesContainer}`);
        mainContainer.classList.add(styles.hovered);
        setTimeout(() => {
            servicesContainer.classList.add(styles.active);
        }, 500);
    }

    const handleMouseLeave = () => {
        const mainContainer = document.querySelector(`.${styles.mainContainer}`);
        const servicesContainer = document.querySelector(`.${styles.servicesContainer}`);
        servicesContainer.classList.remove(styles.active);
        mainContainer.classList.remove(styles.hovered);
    }

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        // When menu is opened, ensure navbar is solid for better visibility
        if (!isMenuOpen) {
            setNavbarStyle({
                backgroundColor: 'rgba(20, 20, 40, 0.3)',
                backdropFilter: 'blur(15px)',
                WebkitBackdropFilter: 'blur(15px)',
                MozBackdropFilter: 'blur(15px)',
                OBackdropFilter: 'blur(15px)',
                msBackdropFilter: 'blur(15px)',
                boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.15)'
            });
        } else {
            // When menu is closed, revert to default style
            setNavbarStyle({
                backgroundColor: 'rgba(20, 20, 40, 0.05)',
                backdropFilter: 'blur(10px)',
                WebkitBackdropFilter: 'blur(10px)',
                MozBackdropFilter: 'blur(10px)',
                OBackdropFilter: 'blur(10px)',
                msBackdropFilter: 'blur(10px)',
                boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.05)'
            });
        }
    };

    const toggleServices = () => {
        setIsServicesOpen(!isServicesOpen);
    };

    return (
        <nav 
            className={styles.mainContainer} 
            style={{ 
                ...navbarStyle,
                transition: 'background-color 0.5s ease-in-out, backdrop-filter 0.5s ease-in-out, box-shadow 0.5s ease-in-out'
            }} 
            onMouseLeave={handleMouseLeave}
            role="navigation"
            aria-label="Huvudnavigation"
        >
            <div className={styles.navContainer}>
                <a href="/" className={styles.logoLink} aria-label="Gå till startsidan">
                    <NeurasiteIconText 
                        width={200} 
                        height={200} 
                        style={{position: "absolute", transform: "translate(0%, -48%)"}}
                    />
                </a>
                <div className={styles.linkContainer} role="menubar">
                    <button 
                        className={styles.navButton}
                        onMouseEnter={handleMouseEnter}
                        aria-expanded={isMenuOpen}
                        aria-haspopup="true"
                    >
                        Tjänster
                    </button>
                    <a href="/priser" className={styles.navLink}>Priser</a>
                    <a href="/about" className={styles.navLink}>Om oss</a>
                </div>
                <button 
                    className={`${styles.hamburgerMenu} ${isMenuOpen ? styles.open : ''}`}
                    onClick={handleHamburgerClick}
                    aria-label={isMenuOpen ? 'Stäng meny' : 'Öppna meny'}
                    aria-expanded={isMenuOpen}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>
            <div 
                className={styles.servicesContainer}
                role="menu" 
                aria-label="Tjänster undermeny"
            >
                {isMobile ? (
                    <div className={styles.mobileMenu}>
                        <div 
                            className={`${styles.mobileMenuButton} ${isServicesOpen ? styles.active : ''}`}
                            onClick={toggleServices}
                        >
                            <h3>Tjänster</h3>
                            <div className={`${styles.mobileMenuArrow} ${isServicesOpen ? styles.rotated : ''}`}></div>
                        </div>
                        {isServicesOpen && (
                            <div className={styles.mobileSubMenu}>
                                <a href="/hemsidor" className={styles.mobileMenuItem}>Hemsidor</a>
                                <a href="/konsultation" className={styles.mobileMenuItem}>Konsultation</a>
                                <a href="/ai-losningar" className={styles.mobileMenuItem}>AI lösningar</a>
                            </div>
                        )}
                        <div className={styles.mobileMenuButton}><h3>Priser</h3></div>
                        <div className={styles.mobileMenuButton}><h3>Om oss</h3></div>
                    </div>
                ) : (
                    <>
                        <a href="/hemsidor" className={styles.serviceLink} role="menuitem">Hemsidor</a>
                        <a href="/konsultation" className={styles.serviceLink} role="menuitem">Konsultation</a>
                        <a href="/ai-losningar" className={styles.serviceLink} role="menuitem">AI lösningar</a>
                    </>
                )}
            </div>
        </nav>
    );
}

export default Navbar;